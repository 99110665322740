<template>
  <div class="print">
    <img src="@/assets/print.png" alt="" />
    <div class="text">打印分享</div>
    <div>
      <el-form label-width="80px">
        <el-form-item label="比赛选择">
          <el-select v-model="value" placeholder="请选择比赛">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: ""
    };
  }
};
</script>

<style lang="scss" scoped>
.print {
  display: flex;
  align-items: center;
  flex-direction: column;
}
img {
  margin: 80px auto 30px;
  display: block;
}
.text {
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #16a085;
  margin-bottom: 20px;
}
</style>
